import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useUID } from 'react-uid';
import { twMerge } from 'tailwind-merge';

import { noop } from 'lib/utils';

import DropdownItem from './DropdownItem';

function Dropdown({
  align = 'left',
  children,
  className,
  id: customId,
  open = false,
  openerRef,
  renderToHTML = false,
  onClickOutside = noop,
  ...rest
}) {
  const dropdownElem = useRef();
  const uniqueId = useUID();
  const id = customId || uniqueId;

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (
        dropdownElem.current &&
        !dropdownElem.current.contains(target) &&
        !openerRef.current.contains(target)
      ) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [dropdownElem, openerRef, onClickOutside]);

  if (!open && !renderToHTML) {
    return null;
  }

  return (
    <div
      ref={dropdownElem}
      className={twMerge(
        'absolute top-full z-10 mt-2 block w-fit overflow-hidden rounded-4 bg-neutral-high-100 text-neutral-low-300 shadow-[0px_0px_10px_0px_rgba(87,87,87,0.2)] dark:bg-neutral-low-500 dark:text-neutral-high-300',
        align === 'left' && 'left-0',
        align === 'right' && 'right-0',
        open ? 'block' : 'hidden',
        className
      )}
      {...rest}
    >
      <div
        aria-labelledby={id}
        id={id}
        role="menu"
        /**
         * is used to suppress errors related of inconsistencies between
         * client-side and server-side of ids generated by 'react-uid'
         */
        suppressHydrationWarning
      >
        {children}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  /**
   * Align component by parent's left or right side
   */
  align: PropTypes.oneOf(['left', 'right']),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Custom identifier for the Dropdown. If this is not provided,
   * we will add a unique id with the prefix `dropdown_`
   */
  id: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * `DropdownItem`s to be rendered as children
   */
  children: PropTypes.node.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Whether the dropdown menu is open
   */
  open: PropTypes.bool,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * The ref of the component that is being used to open the dropdown
   */
  openerRef: PropTypes.shape().isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Whether to render the dropdown to HTML even if its not opened, may be used
   * for SEO purposes
   */
  renderToHTML: PropTypes.bool,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on click
   */
  onClick: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on click outside of the dropdown. Normally used to
   * close it
   */
  onClickOutside: PropTypes.func,
};

export { Dropdown, DropdownItem };
