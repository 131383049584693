/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Avatar from 'shopper/components/Avatar';
import Text from 'shopper/components/Text';

import { toStoreImageAltText } from 'lib/image';
import { toStoreOffersLink } from 'lib/links';

const Store = ({
  backgroundPageLink,
  pageFrom,
  pageImage,
  pageLink,
  pageParam,
  pageTitle,
  showStoreImage = false,
  sponsored = false,
  onClick,
}) => {
  const onClickHandler = (event) => {
    if (sponsored) {
      window.open(pageLink, '_blank');
    }

    onClick(event);
  };

  const Item = (
    <a
      className="grid grid-cols-[24px,auto] grid-rows-2 items-center justify-start gap-x-2"
      data-test-selector="store-dropdown-item"
      href={sponsored && backgroundPageLink}
      rel="noopener noreferrer"
      onClick={onClickHandler}
    >
      {showStoreImage && (
        <Avatar
          alt={toStoreImageAltText(pageTitle)}
          className="row-span-1"
          description={toStoreImageAltText(pageTitle)}
          size="size6"
          src={pageImage}
        />
      )}
      <Text
        className="col-start-2 text-neutral-low-300 dark:text-neutral-high-300"
        size="size3"
        bold
      >
        {pageTitle}
      </Text>
      <Text
        className="col-span-2 row-start-2 text-neutral-high-500 dark:text-neutral-high-400"
        size="size3"
      >
        {pageFrom}
      </Text>
    </a>
  );

  if (sponsored) {
    return Item;
  }

  return (
    <NextLink
      href={sponsored ? pageLink : toStoreOffersLink(pageParam)}
      prefetch={false}
      passHref
    >
      {Item}
    </NextLink>
  );
};

Store.propTypes = {
  backgroundPageLink: PropTypes.string,
  pageFrom: PropTypes.string.isRequired,
  pageImage: PropTypes.string.isRequired,
  pageLink: PropTypes.string,
  pageParam: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  showStoreImage: PropTypes.bool,
  sponsored: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Store;
