import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { Dropdown } from 'shopper/components/Dropdown';
import Heading from 'shopper/components/Heading';

import placeholder from 'lib/placeholder';
import { storeQueryKeys } from 'lib/queryKeys';

import { getStoresList } from 'services/store';

import Footer from './Footer';
import Stores from './Stores';

import LINKS from 'constants/links';
import STORES from 'constants/store';

const StoresDropdown = ({ isOpen, openerRef, onClose }) => {
  const { data: stores } = useQuery({
    queryKey: storeQueryKeys.list({ featured: true }),
    queryFn: ({ signal }) => getStoresList({ from: 'topbar' }, { signal }),
    select: (serverStores) =>
      serverStores.map((store) =>
        store.pageId === STORES.ALIEXPRESS_STORE_ID
          ? {
              ...store,
              backgroundPageLink: LINKS.ALIEXPRESS_FIRST_PURCHASE_DEALS,
              pageLink: LINKS.ALIEXPRESS_BIG_DEALS,
              sponsored: true,
            }
          : { ...store, sponsored: false }
      ),
    enabled: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return (
    <Dropdown
      align="left"
      className="min-w-max rounded-3 bg-neutral-high-100 pt-4 shadow-[0px_0px_10px_0px_rgba(87,87,87,0.2)] dark:border dark:border-neutral-high-200 dark:bg-neutral-low-500"
      data-test-selector="stores-dropdown"
      id="stores-dropdown"
      open={isOpen}
      openerRef={openerRef}
      renderToHTML
      onClickOutside={onClose}
    >
      <Heading as="h2" className="ml-6" size="size5">
        {placeholder('actions.toggleStores')}
      </Heading>
      {stores && (
        <Stores showStoreImages={isOpen} stores={stores} onClose={onClose} />
      )}
      <Footer onClose={onClose} />
    </Dropdown>
  );
};

StoresDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openerRef: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};

export default StoresDropdown;
