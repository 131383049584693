import PropTypes from 'prop-types';

import Store from './Store';

const Stores = ({ showStoreImages = false, stores, onClose }) => (
  <div className="my-4 grid grid-cols-5 gap-6 px-6">
    {stores.map((store) => (
      <Store
        key={store.pageTitle}
        showStoreImage={showStoreImages}
        onClick={onClose}
        {...store}
      />
    ))}
  </div>
);

Stores.propTypes = {
  showStoreImages: PropTypes.bool,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundPageLink: PropTypes.string,
      pageFrom: PropTypes.string.isRequired,
      pageImage: PropTypes.string.isRequired,
      pageLink: PropTypes.string,
      pageParam: PropTypes.string.isRequired,
      pageTitle: PropTypes.string.isRequired,
      showStoreImage: PropTypes.bool,
    })
  ).isRequired,
};

export default Stores;
